import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Session, {
  useSessionContext,
} from 'supertokens-auth-react/recipe/session'
import { EmailVerificationClaim } from 'supertokens-auth-react/recipe/emailverification'
import { setAuthenticated, setUnauthenticated } from 'src/redux/actions'

const useAuth = () => {
  const [user, setUser] = useState({})
  const [emailVerified, setEmailVerified] = useState(false)
  const [isAuthorized, setIsAuthorized] = useState(false)
  const [hasFiredSignup, setHasFiredSignup] = useState(false) // to avoid multiple sign_up pushes
  const session = useSessionContext()
  const dispatch = useDispatch()

  const validateClaims = async () => {
    if (await Session.doesSessionExist()) {
      const validationErrors = await Session.validateClaims()
      if (validationErrors.length === 0) {
        setEmailVerified(true)
      } else {
        for (const err of validationErrors) {
          if (err.validatorId === EmailVerificationClaim.id) {
            setEmailVerified(false)
          }
        }
      }
    }
  }

  useEffect(() => {
    // We only want to push "logout" if user was previously logged in but no longer is.
    // We'll track the previous value of doesSessionExist to detect that transition.
    let previousSessionExist = false

    return () => {
      // Before unmount, or on subsequent effect calls,
      // store the state of whether a session existed
      previousSessionExist = session.doesSessionExist
    }
  }, [])

  useEffect(() => {
    if (!session.loading) {
      const { doesSessionExist, userId, accessTokenPayload } = session

      // If there's NO session
      if (!doesSessionExist) {
        dispatch(setUnauthenticated())
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'logout',
          user_id: user?.userId ?? '',
        })
      } else {
        dispatch(setAuthenticated())

        const email = accessTokenPayload.email
        setUser({ email, userId })
        setIsAuthorized(true)

        // "login" event
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'login',
          user_id: userId,
        })

        validateClaims()
      }
    }
  }, [session, dispatch])

  useEffect(() => {
    if (emailVerified && user?.userId && !hasFiredSignup) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'sign_up',
        user_id: user.userId,
      })
      setHasFiredSignup(true)
    }
  }, [emailVerified, user, hasFiredSignup])

  return { user, emailVerified, isAuthorized }
}

export default useAuth
