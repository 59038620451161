import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import Image from 'next/image'
import useWidth from 'src/hooks/useWidth'
import { NewsFeedWidget } from './Widgets/NewsFeed'
import { StatisticsWidget } from './Widgets/Statistics'
import { FooterNavbar } from '../FooterNavbar'
import styles from './styles.module.scss'
import {
  Route,
  RouteEventType,
  WalletEventTypes,
  Widget,
  WidgetConfig,
  widgetEventEmitter,
  WidgetEvents,
} from '@rango-dev/widget-embedded'
import {
  getUserPoints,
  getAnonymUserPoints,
  saveAnonymUserTransaction,
  saveUserTransaction,
} from 'src/services/userServices'
import useAuth from 'src/hooks/useAuth'
import { MainWidget } from './Widgets/MainWidget'

const config: WidgetConfig = {
  title: ' ',
  apiKey: '2680af7d-9f6e-4ed4-a547-ac05368437fe',
  walletConnectProjectId: 'e24844c5deb5193c1c14840a7af6a40b',
  affiliate: {
    ref: 'Gptzic',
    percent: 0.3,
    wallets: {
      ETH: '0x0A6687A6d29495A3BF9b2672606123DFbC87103D',
      BSC: '0x0A6687A6d29495A3BF9b2672606123DFbC87103D',
      POLYGON: '0x0A6687A6d29495A3BF9b2672606123DFbC87103D',
      SOLANA: '7VjQTLkuJ7EVSJWG1EtAqW2oA9hrdhAs8ghfxYMXNPFz',
    },
  },
  theme: {
    mode: 'dark',
    colors: {
      dark: {
        background: '#000',
        primary: '#1C3CF1',
      },
    },
    borderRadius: 0,
  },
}

// Popup Component
const SignUpPopup = ({ onClose }) => {
  const handleOverlayClick = e => {
    if (e.target === e.currentTarget) {
      onClose()
    }
  }

  return (
    <div className={styles.overlay} onClick={handleOverlayClick}>
      <div className={styles.popup}>
        <Image
          className={styles.closeIcon}
          src='./assets/icons/icon-close.svg'
          width={25}
          height={25}
          alt='close'
          onClick={onClose} // Add this line to ensure the icon can close the popup
        />
        <div className={styles.header}>Subscribe to our weekly newsletter</div>
        <iframe
          src='https://cdn.forms-content.sg-form.com/98388cb6-dd00-11ed-bba7-b2a480f00fab'
          width='100%'
          height='100%'
          frameBorder='0'
        />
      </div>
    </div>
  )
}

export const RightSidebar = ({ setIsSidebarOpened }) => {
  const { isMobile, getWidthWindow } = useWidth() // Using getWidthWindow for screen width
  const [rightSidebar, setRightSidebar] = useState(!isMobile)
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const { isAuthorized } = useAuth()
  const [walletId, setWalletId] = useState(null)

  const [isReady, setReady] = useState(false)
  useEffect(() => setReady(true), [])

  const [loyaltyPoints, setLoyaltyPoints] = useState(0)

  useEffect(() => {
    if (isAuthorized) {
      getUserPoints().then(response => setLoyaltyPoints(response.result))
    }
  }, [isAuthorized])

  useEffect(() => {
    widgetEventEmitter.on(WidgetEvents.RouteEvent, routeEvent => {
      const { event } = routeEvent

      if (event.type === RouteEventType.SUCCEEDED) {
        onSwapSuccess(routeEvent.route)
      } else if (event.type === RouteEventType.FAILED) {
        console.log('swap error: ', routeEvent)
      }
    })

    widgetEventEmitter.on(WidgetEvents.WalletEvent, walletEvent => {
      if (
        walletEvent.type === WalletEventTypes.CONNECT &&
        walletEvent.payload.accounts.length > 0
      ) {
        const address = walletEvent.payload.accounts[0].address
        setWalletId(address)

        if (!isAuthorized) {
          getAnonymUserPoints(address).then(response =>
            setLoyaltyPoints(response.result)
          )
        }
      }
    })

    return () => {
      widgetEventEmitter.off(WidgetEvents.RouteEvent)
      widgetEventEmitter.off(WidgetEvents.WalletEvent)
    }
  }, [isAuthorized])

  const onSwapSuccess = (payload: Route) => {
    if (payload.steps.length === 0) return

    const txId = payload.requestId
    const inputAmount = payload.inputAmount
    const inputSymbol = payload.steps[0].fromSymbol

    if (isAuthorized) {
      saveUserTransaction(txId, inputAmount, inputSymbol)
    } else {
      saveAnonymUserTransaction(walletId, txId, inputAmount, inputSymbol)
    }
  }

  useEffect(() => {
    if (isMobile) {
      setRightSidebar(false)
      setIsSidebarOpened(false)
    }
  }, [isMobile, setIsSidebarOpened])

  return (
    <>
      <div
        className={clsx(styles.rightSidebarButtom, {
          [styles.rightSidebarButtomClosed]: !rightSidebar,
        })}
      >
        <Image
          src={`/assets/icons/icon-right-sidebar-${
            rightSidebar ? 'closed' : 'open'
          }.svg`}
          width='20'
          height='20'
          alt='sidebar icon'
          onClick={() => {
            setRightSidebar(!rightSidebar)
            setIsSidebarOpened(!rightSidebar)
          }}
        />
      </div>
      <div
        className={clsx(styles.rightSidebar, {
          [styles.rightSidebarOpen]: rightSidebar,
        })}
      >
        {rightSidebar && (
          <>
            <div className={styles.widgetsContainer}>
              <MainWidget
                title={
                  <div className={styles.pointsContainer}>
                    <div className={styles.logoContainer}>
                      <Image
                        src='/assets/points-logo.svg'
                        width={25}
                        height={25}
                        alt='points'
                      />
                    </div>
                    <div className={styles.pointsText}>
                      Swap and Earn Points
                    </div>
                  </div>
                }
              >
                {isReady && <Widget config={config} />}
              </MainWidget>

              {!isMobile && <NewsFeedWidget />}
              {/* Conditionally render NewsFeedWidget */}
              <StatisticsWidget />
            </div>
            <FooterNavbar />
          </>
        )}
      </div>
      {isPopupOpen && <SignUpPopup onClose={() => setIsPopupOpen(false)} />}
    </>
  )
}
