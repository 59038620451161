import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import useWidth from 'src/hooks/useWidth'
import { useOverlayTriggerState } from '@react-stately/overlays'
import styles from './styles.module.scss'
import { Dialog } from 'src/components/Dialog'
import { SignInAndUp } from 'supertokens-auth-react/recipe/thirdpartyemailpassword'
import Image from 'next/image'

export const SessionExpireModal = ({ openModal, setOpenModal }) => {
  const { isMobile } = useWidth()
  const dialog = useOverlayTriggerState({})
  const [login, setLogin] = useState(false)

  useEffect(() => {
    if (openModal) {
      dialog.open()
    }
  }, [openModal])

  useEffect(() => {
    if (!dialog.isOpen) {
      setOpenModal(false)
      setLogin(false)
      setLogin(false)
    }

    return () => {
      setOpenModal(false)
    }
  }, [dialog.isOpen, setOpenModal])

  return (
    <Dialog
      dismissable
      headerless
      padding={false}
      onClose={dialog.close}
      open={dialog.isOpen}
      title=''
      dialogClassName={styles.dialogClassName}
    >
      {!login ? (
        <div className={clsx(styles.container)}>
          <div className={styles.title}>Session Expired</div>
          <div className={styles.description}>
            Your session has expired. Please log in again to continue having
            access to your account.
          </div>

          <span onClick={() => setLogin(true)} className={clsx(styles.button)}>
            <Image
              src={`/assets/icons/icon-user.svg`}
              width='22'
              height='22'
              alt='icon user'
            />
            Log In
          </span>
        </div>
      ) : (
        <SignInAndUp />
      )}
    </Dialog>
  )
}
